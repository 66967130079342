import Carousel from '../../modules/carousel/carousel';

/**
 * Make instances
 */
const swiperElements = document.getElementsByClassName('js-media-swiper') as HTMLCollectionOf<HTMLElement>;
const options = {
  initialSlide: 2,
  loop: true,
  centeredSlides: true,
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
  spaceBetween: 0,
  slidesPerView: 2,
  loopedSlides: 2,
  speed: 450,
  autoplay: {
    delay: 2000,
  },
};
const carouselArray = [];
// Make instances for each media
for (let i = 0; i < swiperElements.length; i++) {
  carouselArray.push(new Carousel(swiperElements[i], options, 'sp'));
}

// Run init on resize
let timeoutId: any;
window.addEventListener('resize', () => {
  clearTimeout(timeoutId);
  timeoutId = setTimeout(() => {
    carouselArray.forEach(carousel => {
      carousel.init();
    });
  }, 200);
});
